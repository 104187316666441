export const environment = {
  production: true,

  serverUrl: 'https://aws-api.10ttech.com/preprod-cssp/gsma/rsp2/es2plus',
  prodUrl: 'https://aws-api.10ttech.com/prod-cssp/gsma/rsp2/es2plus',
  authUrl: 'https://aws-api.10ttech.com/user-api',
  notifUrl: 'https://app-api.10ttech.com',
  loginUrl: 'https://signin.my.10ttech.com/auth/signin',
  userServiceAPIUrl: 'https://aws-api.10ttech.com/esim-suite-user-service',
  languageArray: ['en', 'es', 'ru', 'pt', 'sv', 'zh', 'bm'],
  langRegex: /en|es|ru|mr|pt|sv|zh|bm/,
  languageList: [
    { name: 'English', key: 'en' },
    { name: 'Russian', key: 'ru' },
    { name: 'Portuguese', key: 'pt' },
    { name: 'Marathi', key: 'mr' },
    { name: 'Swedish', key: 'sv'},
    { name: 'Simplified Chinese', key: 'zh'},
    { name: 'Bahasa Malaysia', key: 'bm'}
  ]
};
